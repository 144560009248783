<template>
  <div>
    <div style="padding: 20px;">
      <logoComponent />
    </div>
    <div class="d-flex align-items-center" style="margin-top: 200px;">
      <CContainer fluid>
        <CRow class="justify-content-center">
          <CCol md="6">
            <CForm v-on:submit.prevent="login()">
              <h1 class="text-center">Eclerk - Se connecter</h1>
              <CInput
                class="mt-4"
                placeholder="Renseignez votre email"
                label="Email"
                type="email"
                size="lg"
                v-model="username"
                @input="$v.username.$touch()"
                :isValid="$v.username.$dirty ? !$v.username.$error : null"
              >
              </CInput>
              <CInput
                placeholder="Renseignez votre mot de passe"
                label="Mot de passe"
                type="password"
                size="lg"
                v-model="password"
                @input="$v.password.$touch()"
                :isValid="$v.password.$dirty ? !$v.password.$error : null"
              >
              </CInput>
              <div
                class="text-danger"
                style="margin-top:-10px"
                   v-if="errorLogin">
                   <small>Veuillez vérifier votre email et mot de passe</small>
              </div>
              <small> <router-link to="/pages/settings/reset-password"> Mot de passe oublié ?</router-link> </small>
              <CRow class="mt-4">
                <CCol class="text-center">
                  <CButton type="submit" shape="pill" color="primary" block class="px-4">Se connecter</CButton>
                </CCol>
              </CRow>

              <div class="mt-3">
                  <small>Pas encore de compte ?
                  <router-link to="/pages/inscription">Inscrivez-vous gratuitement</router-link></small>
              </div>
            </CForm>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  </div>
</template>

<script>
import userMixins from '@/mixins/userMixins'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import logoComponent from '@/components/logoComponent'

export default {
  name: 'Login',
  components: {
    logoComponent
  },
  mixins: [
    validationMixin,
    userMixins
  ],
  data: function () {
    return {
      password: '',
      errorLogin: false
    }
  },
  computed: {
    username: {
      get () {
        return this.$store.state.loginUsername
      },
      set (value) {
        this.$store.commit('setLoginUsernameLocalStorage', value)
      }
    },
  },

  validations: {
    username: {
      required,
    },
    password: {
      required,
    }
  },
  methods: {
    login: function () {
     var username = this.username
     var password = this.password
     this.errorLogin = false
     this.$store.dispatch('AUTH_REQUEST', { username, password })
      .then((result) => {
        const stripe_subscription_id = result.data.stripe_subscription_id
        const stripe_subscription_is_active = result.data.stripe_subscription_is_active
        const stripe_subscription_current_period_end = result.data.stripe_subscription_current_period_end

        const stripe_subscription_is_trial = result.data.stripe_subscription_is_trial
        const stripe_subscription_trial_end = result.data.stripe_subscription_trial_end

        const now = parseInt(Date.now() / 1000)
        var isBoss = false

        var userGroupsArray = []
        for (var i = 0; i < result.data.groups.length; i++) {
          userGroupsArray.push(
            result.data.groups[i]['name']
          )
        }

        if (userGroupsArray.indexOf('BOSS') > -1) {
          isBoss = true
        }
        if (isBoss) {
          if (stripe_subscription_id === '') {
            // On l'envoie vers la page de l'abonnement
            this.$router.push('/abonnement/creation')
          }
          else if (stripe_subscription_is_trial == true && parseFloat(stripe_subscription_trial_end) >= now) {
            // Essai gratuit valide
            this.$router.push('/')
          }
          else if (stripe_subscription_is_active == true && parseFloat(stripe_subscription_current_period_end) >= now) {
            // Souscription valide
            this.$router.push('/')
          }

          else if (stripe_subscription_is_trial == true && parseFloat(stripe_subscription_trial_end) < now)  {
            // Essai gratuit expiré
            this.$router.push('/pages/administrateur/abonnement/periode-essai/expire')
          }
          else if (stripe_subscription_is_active == false || stripe_subscription_current_period_end < now)  {
            // Souscription expiére
            this.$router.push('/pages/administrateur/abonnement/invalide')
          }
          else {
            this.$router.push('/')
          }
        }
        else {
          if (stripe_subscription_id === '') {
            // On l'envoie vers la page de l'abonnement
            this.$router.push('/pages/abonnement/invalide')
          }
          else if (stripe_subscription_is_trial == true && parseFloat(stripe_subscription_trial_end) >= now) {
            // Essai gratuit valide
            this.$router.push('/')
          }
          else if (stripe_subscription_is_active == true && parseFloat(stripe_subscription_current_period_end) >= now) {
            // Souscription valide
            this.$router.push('/')
          }

          else if (stripe_subscription_is_trial == true && parseFloat(stripe_subscription_trial_end) < now)  {
            // Essai gratuit expiré
            this.$router.push('/pages/abonnement/periode-essai/expire')
          }
          else if (stripe_subscription_is_active == false || stripe_subscription_current_period_end < now)  {
            // Souscription expiére
            this.$router.push('/pages/abonnement/invalide')
          }
          else {
            this.$router.push('/')
          }
        }
      })
      .catch(() => {
        this.errorLogin = true
      })
   },
  }
}
</script>
